// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-acknowledgements-js": () => import("./../../../src/pages/about/acknowledgements.js" /* webpackChunkName: "component---src-pages-about-acknowledgements-js" */),
  "component---src-pages-about-all-logos-plain-js": () => import("./../../../src/pages/about/all-logos-plain.js" /* webpackChunkName: "component---src-pages-about-all-logos-plain-js" */),
  "component---src-pages-about-board-js": () => import("./../../../src/pages/about/board.js" /* webpackChunkName: "component---src-pages-about-board-js" */),
  "component---src-pages-about-consortium-js": () => import("./../../../src/pages/about/consortium.js" /* webpackChunkName: "component---src-pages-about-consortium-js" */),
  "component---src-pages-about-industrial-partners-js": () => import("./../../../src/pages/about/industrial-partners.js" /* webpackChunkName: "component---src-pages-about-industrial-partners-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-about-key-researchers-js": () => import("./../../../src/pages/about/key-researchers.js" /* webpackChunkName: "component---src-pages-about-key-researchers-js" */),
  "component---src-pages-about-logos-templates-js": () => import("./../../../src/pages/about/logos-templates.js" /* webpackChunkName: "component---src-pages-about-logos-templates-js" */),
  "component---src-pages-about-management-js": () => import("./../../../src/pages/about/management.js" /* webpackChunkName: "component---src-pages-about-management-js" */),
  "component---src-pages-draft-news-js": () => import("./../../../src/pages/draft-news.js" /* webpackChunkName: "component---src-pages-draft-news-js" */),
  "component---src-pages-events-js": () => import("./../../../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-reports-js": () => import("./../../../src/pages/reports.js" /* webpackChunkName: "component---src-pages-reports-js" */),
  "component---src-pages-results-conference-papers-js": () => import("./../../../src/pages/results/conference_papers.js" /* webpackChunkName: "component---src-pages-results-conference-papers-js" */),
  "component---src-pages-results-demonstrations-js": () => import("./../../../src/pages/results/demonstrations.js" /* webpackChunkName: "component---src-pages-results-demonstrations-js" */),
  "component---src-pages-results-js": () => import("./../../../src/pages/results.js" /* webpackChunkName: "component---src-pages-results-js" */),
  "component---src-pages-results-manual-demos-js": () => import("./../../../src/pages/results/manual_demos.js" /* webpackChunkName: "component---src-pages-results-manual-demos-js" */),
  "component---src-pages-results-media-js": () => import("./../../../src/pages/results/media.js" /* webpackChunkName: "component---src-pages-results-media-js" */),
  "component---src-pages-results-presentations-js": () => import("./../../../src/pages/results/presentations.js" /* webpackChunkName: "component---src-pages-results-presentations-js" */),
  "component---src-pages-results-publications-js": () => import("./../../../src/pages/results/publications.js" /* webpackChunkName: "component---src-pages-results-publications-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-event-js": () => import("./../../../src/templates/event.js" /* webpackChunkName: "component---src-templates-event-js" */)
}

